import { instantMeiliSearch } from '@meilisearch/instant-meilisearch'

export const useMeilisearch = () => {
  // Get the Nuxt app instance
  const nuxtApp = useNuxtApp()

  // Try to get the global client from the plugin
  if (nuxtApp.$meilisearch && nuxtApp.$meilisearch.searchClient) {
    console.log('Using global Meilisearch client from plugin')
    return {
      searchClient: nuxtApp.$meilisearch.searchClient,
    }
  }

  // Fallback to creating a new client
  console.log('Global client not available, creating a new one')

  // Get Meilisearch configuration from runtime config and ensure they are strings
  const meilisearchUrl = String(
    useNuxtApp().$config.public.meilisearchUrl || ''
  )
  const meilisearchKey = String(
    useNuxtApp().$config.public.meilisearchKey || ''
  )

  // For debugging
  console.log('Runtime config:', {
    meilisearchUrl,
    meilisearchKey: meilisearchKey ? '[REDACTED]' : 'not set',
  })

  // Validate configuration
  if (!meilisearchUrl) {
    console.error('Meilisearch URL is not configured in runtime config')
    return { searchClient: null }
  }

  // Create Meilisearch client
  try {
    const searchClient = instantMeiliSearch(meilisearchUrl, meilisearchKey, {
      primaryKey: 'id',
      finitePagination: true,
      placeholderSearch: false,
      keepZeroFacets: false,
    }).searchClient

    console.log('Meilisearch client initialized successfully')

    return {
      searchClient,
    }
  } catch (error) {
    console.error('Error initializing Meilisearch client:', error)
    return { searchClient: null }
  }
}
