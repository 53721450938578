<script setup lang="ts">
  import { MagnifyingGlassIcon } from '@heroicons/vue/20/solid'
  import {
    BuildingStorefrontIcon,
    FaceFrownIcon,
    MapPinIcon,
    XMarkIcon,
  } from '@heroicons/vue/24/outline'
  import 'instantsearch.css/themes/algolia-min.css'
  import { computed, nextTick, onMounted, onUnmounted, ref } from 'vue'
  import { useMeilisearch } from '~/composables/useMeilisearch'
  import { useStringHelpers } from '~/composables/useStringHelpers'
  import { useUrlHelpers } from '~/composables/useUrlHelpers'
  import { useSearchStore } from '~/stores/searchStore'
  import SpaceService from '~/services/SpaceService'
  import ProviderService from '~/services/ProviderService'
  import { useRouter } from 'vue-router'

  const router = useRouter()
  const searchStore = useSearchStore()
  const { normalizeStringForUrl } = useStringHelpers()

  const { searchClient } = useMeilisearch()

  const activeSearchClient = computed(() => searchClient)

  const searchBoxRef = ref(null)
  const searchContainerRef = ref(null)
  const searchQuery = ref('')
  const showResults = ref(false)
  const hasSearched = ref(false)
  const selectedItem = ref(null)

  const closeResults = () => {
    showResults.value = false
  }

  const clearSearch = (refine) => {
    searchQuery.value = ''
    hasSearched.value = false
    selectedItem.value = null
    if (typeof refine === 'function') {
      refine('')
    }
    closeResults()
  }

  const handleMeilisearchResultClick = (item, refine, type) => {
    const itemCopy = { ...item }
    itemCopy.type = type

    if (type === 'location') {
      selectedItem.value = {
        ...itemCopy,
        type: 'location',
        neighborhood: itemCopy.address.neighborhood || '',
        city: itemCopy.address.city || '',
      }

      closeResults()
      nextTick(() => {
        if (typeof refine === 'function') {
          refine(selectedItem.value.address.neighborhood)
        }
        searchQuery.value = selectedItem.value.address.neighborhood
      })
    } else if ((type === 'space' || type === 'provider') && itemCopy.name) {
      selectedItem.value = {
        ...itemCopy,
        type,
      }

      closeResults()
      nextTick(() => {
        if (typeof refine === 'function') {
          refine(selectedItem.value.name)
        }
        searchQuery.value = selectedItem.value.name
      })
    }
  }

  const handleClickOutside = (event) => {
    if (
      searchContainerRef.value &&
      !searchContainerRef.value.contains(event.target)
    ) {
      closeResults()
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      closeResults()
    }
  }

  const handleSearchButtonClick = async () => {
    if (selectedItem.value) {
      if (selectedItem.value.type === 'location') {
        router.push({
          name: 'search-city-neighborhood',
          params: {
            state: normalizeStringForUrl(selectedItem.value.address.state || ''),
            city: normalizeStringForUrl(selectedItem.value.address.city || ''),
            neighborhood: selectedItem.value.address.neighborhood
              ? normalizeStringForUrl(selectedItem.value.address.neighborhood)
              : '',
          },
        })
      } else if (selectedItem.value.type === 'provider') {
        try {
          const provider = await ProviderService.findById(selectedItem.value.id.toString())
          router.push({
            path: useUrlHelpers().getProviderUrl(provider),
          })
        } catch (error) {
          console.error('Error fetching provider data:', error)
          router.push(`/fornecedores/${selectedItem.value.id}`)
        }
      } else {
        router.push({
          path: useUrlHelpers().getSpaceUrl(selectedItem.value),
        })
      }
      return
    }

    const searchResult = searchStore.searchResultSelected as any

    if (searchResult.searchType === 'space') {
      const space = await SpaceService.getSpaceById(
        searchResult.spaceId.toString()
      )
      
      router.push({
        name: 'space',
        params: {
          id: space.id,
          city: normalizeStringForUrl(space.address.city),
          neighborhood: normalizeStringForUrl(space.address.neighborhood),
          name: normalizeStringForUrl(space.name),
        },
      })
    } else if (searchResult.searchType === 'provider') {
      const provider = await ProviderService.findById(
        searchResult.providerId.toString()
      )
      router.push({
        path: useUrlHelpers().getProviderUrl(provider),
      })
    }
  }

  const handleSearchInput = (value) => {
    searchQuery.value = value
    if (selectedItem.value) {
      const selectedValue =
        selectedItem.value.type === 'location'
          ? selectedItem.value.address.neighborhood
          : selectedItem.value.name

      if (selectedValue !== value) {
        selectedItem.value = null
      }
    }
    showResults.value = value.length > 0
    if (value.length > 0) {
      hasSearched.value = true
    }
  }

  onMounted(() => {
    document.addEventListener('click', handleClickOutside)
    document.addEventListener('keydown', handleKeyDown)
  })

  onUnmounted(() => {
    document.removeEventListener('click', handleClickOutside)
    document.removeEventListener('keydown', handleKeyDown)
  })
</script>

<template>
  <div
    ref="searchContainerRef"
    class="relative w-full"
    style="--search-width: 100%">
    <div class="relative mt-4 rounded-md shadow-sm md:flex">
      <ais-instant-search
        :search-client="activeSearchClient"
        index-name="spaces">
        <div class="relative w-full">
          <div
            class="pointer-events-none absolute inset-y-0 left-0 z-50 flex items-center pl-3">
            <MagnifyingGlassIcon class="h-5 w-5 text-gray-400" />
          </div>

          <ais-search-box
            ref="searchBoxRef"
            class="w-full">
            <template #default="{ currentRefinement, refine }">
              <div class="relative w-full">
                <input
                  type="text"
                  :value="searchQuery"
                  class="block w-full rounded-l-md rounded-r-md border-0 py-3 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-gray-400 sm:text-sm sm:leading-6 md:rounded-r-none"
                  placeholder="O que você está procurando?"
                  @input="
                    refine($event.currentTarget.value),
                      handleSearchInput($event.currentTarget.value)
                  "
                  @keydown.esc="clearSearch(refine)" />
                <button
                  v-if="currentRefinement.length > 0"
                  class="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600"
                  type="button"
                  @click="clearSearch(refine)">
                  <span class="sr-only">Limpar busca</span>
                  <XMarkIcon class="h-5 w-5 text-gray-400" />
                </button>
              </div>
            </template>
          </ais-search-box>
        </div>

        <button
          type="button"
          class="z-20 -ml-px mt-2 flex w-full items-center justify-center gap-x-1.5 rounded-l-md rounded-r-md bg-orange-400 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-orange-300 hover:bg-orange-500 md:relative md:mt-0 md:inline-flex md:w-auto md:flex-shrink-0 md:rounded-l-none md:rounded-r-md"
          @click="handleSearchButtonClick">
          <span class="">Pesquisar</span>
        </button>

        <!-- Container para exibir os resultados estilizados -->
        <div
          v-if="showResults"
          class="absolute left-0 right-0 z-50 mt-1 max-h-80 w-full overflow-y-auto rounded-md bg-white p-4 shadow"
          style="top: 100%">
          <!-- Índice spaces -->
          <ais-index index-name="spaces">
            <template #default>
              <h3 class="mb-2 mt-4 text-sm font-semibold text-gray-700">
                Espaços
              </h3>
              <ais-hits>
                <template #default="{ items, results, refine }">
                  <div
                    v-if="
                      items.length === 0 &&
                      (!results || !results.query) &&
                      hasSearched
                    "
                    class="flex items-center justify-center p-4 text-gray-500">
                    <FaceFrownIcon class="mr-2 h-5 w-5 text-gray-400" />
                    <span>Não encontramos espaços para a sua busca</span>
                  </div>
                  <ul v-else-if="items.length > 0">
                    <li
                      v-for="item in items.slice(0, 15)"
                      :key="item.id"
                      class="flex transform justify-between px-2 py-2 transition-all duration-200 ease-out hover:cursor-pointer hover:border-l-2 hover:border-orange-400"
                      @click="
                        handleMeilisearchResultClick(item, refine, 'space')
                      ">
                      <span class="text-sm text-gray-900">
                        <ais-highlight
                          :hit="item"
                          attribute="name" />
                        <span
                          v-if="item.address.neighborhood || item.address.city"
                          class="text-gray-500">
                          {{
                            item.address.neighborhood
                              ? ` - ${item.address.neighborhood}`
                              : ''
                          }}
                          {{
                            item.address.city ? ` - ${item.address.city}` : ''
                          }}
                        </span>
                      </span>
                      <span class="text-xs text-gray-500">
                        <BuildingStorefrontIcon
                          class="inline-block h-4 w-4 text-gray-400" />
                      </span>
                    </li>
                  </ul>
                </template>
              </ais-hits>
            </template>
          </ais-index>

          <!-- Índice providers -->
          <ais-index index-name="providers">
            <template #default>
              <ais-configure :hits-per-page="5" />
              <h3 class="mb-2 mt-4 text-sm font-semibold text-gray-700">
                Fornecedores
              </h3>
              <ais-hits>
                <template #default="{ items, results, refine }">
                  <div
                    v-if="
                      items.length === 0 &&
                      (!results || !results.query) &&
                      hasSearched
                    "
                    class="flex items-center justify-center p-4 text-gray-500">
                    <FaceFrownIcon class="mr-2 h-5 w-5 text-gray-400" />
                    <span>Não encontramos fornecedores para a sua busca</span>
                  </div>
                  <ul v-else-if="items.length > 0">
                    <li
                      v-for="item in items.slice(0, 10)"
                      :key="item.id"
                      class="flex transform justify-between px-2 py-2 transition-all duration-200 ease-out hover:cursor-pointer hover:border-l-2 hover:border-orange-400"
                      @click="
                        handleMeilisearchResultClick(item, refine, 'provider')
                      ">
                      <span class="text-sm text-gray-900">
                        <ais-highlight
                          :hit="item"
                          attribute="name" />
                        <span
                          v-if="item.address && (item.address.state || item.address.city)"
                          class="text-gray-500">
                          {{ item.address.state ? ` - ${item.address.state}` : '' }}
                          {{ item.address.city ? `, ${item.address.city}` : '' }}
                        </span>
                        <span
                          v-if="item.category"
                          class="ml-1 text-xs font-medium text-orange-600">
                          {{ item.category }}
                        </span>
                      </span>
                      <span class="text-xs text-gray-500">
                        <BuildingStorefrontIcon
                          class="inline-block h-4 w-4 text-gray-400" />
                      </span>
                    </li>
                  </ul>
                </template>
              </ais-hits>
            </template>
          </ais-index>

          <!-- Índice locations -->
          <ais-index index-name="locations">
            <template #default>
              <h3 class="mb-2 text-sm font-semibold text-gray-700">
                Localizações
              </h3>
              <ais-hits>
                <template #default="{ items, results, refine }">
                  <div
                    v-if="
                      items.length === 0 &&
                      (!results || !results.query) &&
                      hasSearched
                    "
                    class="flex items-center justify-center p-4 text-gray-500">
                    <FaceFrownIcon class="mr-2 h-5 w-5 text-gray-400" />
                    <span>Não encontramos localizações para a sua busca</span>
                  </div>
                  <ul v-else-if="items.length > 0">
                    <li
                      v-for="item in items.slice(0, 15)"
                      :key="item.id"
                      class="flex transform justify-between px-2 py-2 transition-all duration-200 ease-out hover:cursor-pointer hover:border-l-2 hover:border-orange-400"
                      @click="
                        handleMeilisearchResultClick(item, refine, 'location')
                      ">
                      <span class="text-sm text-gray-900">
                        <ais-highlight
                          :hit="item"
                          attribute="neighborhood" />
                        <span
                          v-if="item.address.city"
                          class="text-gray-500">
                          {{ item.address.city ? ` - ${item.address.city}` : '' }}
                        </span>
                      </span>
                      <span class="text-xs text-gray-500">
                        <MapPinIcon
                          class="inline-block h-4 w-4 text-gray-400" />
                      </span>
                    </li>
                  </ul>
                </template>
              </ais-hits>
            </template>
          </ais-index>
        </div>
      </ais-instant-search>
    </div>
  </div>
</template>

<style scoped>
  .ais-SearchBox {
    width: 100%;
    display: flex;
  }

  .ais-SearchBox-form {
    width: 100%;
    display: flex;
  }

  .ais-SearchBox-input {
    width: 100%;
  }

  /* Esconder elementos padrão do InstantSearch que não queremos mostrar */
  .ais-SearchBox-submit,
  .ais-SearchBox-reset,
  .ais-SearchBox-loadingIndicator {
    display: none;
  }

  /* Estilos adicionais para garantir que o componente ocupe todo o espaço */
  .ais-InstantSearch {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  @media (min-width: 768px) {
    .ais-InstantSearch {
      flex-direction: row;
    }
  }

  /* Garantir que o input ocupe todo o espaço disponível */
  .ais-SearchBox div {
    width: 100%;
  }

  .debug-icon {
    /* Garantir que o ícone seja visível */
    display: block !important;
    opacity: 1 !important;
    visibility: visible !important;
    z-index: 100 !important;
  }
</style>
